import React from "react";
import Tiles from "./Tiles";

const Home = () => {
  return (
    <div>
      <Tiles />
    </div>
  );
};

export default Home;
